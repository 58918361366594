<template>
  <div>
    <LeadForm @submit="createLead" @cancel="$router.go(-1)" ref="leadForm">
      <template v-slot="{ form }">
        <v-row dense v-if="form.additional_information && form.additional_information.length > 0">
          <v-col>
            <v-card outlined>
              <v-card-title>{{ __('Informações adicionais', 'new_lead') }}</v-card-title>
              
              <div 
                class="mb-3 mx-2"
                v-for="(information, index) in form.additional_information"
                :key="'additional-info' + index"
              >
                <v-card 
                  flat 
                  color="#f1f1f1"
                >
                  <v-card-text>
                    {{ information }}
                  </v-card-text>
                </v-card>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </LeadForm>

    <v-dialog v-model="loading" width="300" persistent>
        <v-card color="primary" dark>
            <v-card-text>
                {{loadingProgressionText}}
                <v-progress-linear
                    :value="loadingProgressionValue"
                    color="white"
                    class="mb-0"
                ></v-progress-linear>
            </v-card-text>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LeadForm from "@/components/Lead/Form/LeadForm";
import LeadMixin from "@/mixins/LeadMixin";
import i18n from '@/mixins/i18n'; 

export default {
  mixins: [i18n],
  components: {
      LeadForm,
  },

  mixins: [ LeadMixin ],
  computed: {
    lead() {
      return this.$store.getters['lead_form/getLead'];
    }
  },

  created() {
    if (!this.lead) {
      const insurerId = this.$route.params.insurer_id;
      this.$router.push({ name: 'lead_new_import_file', params: { insurer_id: insurerId } });
    }
  },

  async mounted() {
    this.$refs.leadForm.fill({
        ...this.lead,
        is_insured_participant: true,
        insured: this.lead?.participant_insured?.contact,
        city: this.lead?.risk_location?.city,
        city_id: this.lead?.risk_location?.city?.id,
        city_area: this.lead?.risk_location?.city_area,
        city_area_id: this.lead?.risk_location?.city_area?.id,
        country_area: this.lead?.risk_location?.country_area,
        country_area_id: this.lead?.risk_location?.country_area?.id,
        postal_code: this.lead?.risk_location?.postal_code,
        street: this.lead?.risk_location?.street,
        number: this.lead?.risk_location?.number,
        policy: {
          ...this.lead?.policy,
          insurance_objects: this.lead?.policy?.insurance_objects || [],
          coverages: this.lead?.policy?.coverages || [],
        }
    });
  }
}
</script>

<style>

</style>